<template>
  <div
      v-click-outside="() => show = false"
      :class="{ search_header: header }" class="search"
  >
    <div
        class="form_field"
    >
      <input
          :id="uniqueId"
          ref="search_elem"
          v-model="search"
          :placeholder="`${$t('search_item')}...`"
          autocomplete="off"
          class="form_input with-icon"
          type="text"
          @focus="onFocus"
          @input="sendGtag"
          @keyup.enter="setSearch"
          @keydown.up.prevent="onArrowUp"
          @keydown.down.prevent="onArrowDown"
          @keydown.enter.prevent="onEnter"
      />
      <label :for="uniqueId">
        <span class="icon f fc wh43">
          <i v-if="loading" class="i-loading" />
          <i v-else class="icon i-search f18 wh43" />
        </span>
      </label>
      <button
          v-if="showClear"
          :class="{header}"
          aria-label="Clear"
          class="btn wh43 btn_clear"
          @click="clearSearch()"
      >
        <i class="i-times f14"></i>
      </button>
      <button
          v-if="header"
          aria-label="Advanced search"
          class="btn btn-default wh43 btn_advanced"
          @click="openAdvancedSearchModal()"
      >
        <svg height="20" width="20">
          <use xlink:href="/icons/sprite.svg#i-settings"></use>
        </svg>
        <!--        <i class="i-filter f20"></i>-->
      </button>
    </div>
    <div v-show="show" :class="{ open: show }" class="search_wrap">
      <ul :class="{p0: !items.length }" class="list search_list">
        <li
            v-for="(item, i) of items"
            :key="i"
            :class="{ 'is-selected': selectedIndex === i }"
            class="item"
        >
          <button
              :ref="el => { refs[item.document.link] = el }"
              class="btn btn-primary fg tal"
              @click.prevent="setItem($event, item.document.title, item.document?.link)">
            <span
                :style="item.document.background_color ? `color: ${item.document.background_color};` : null"
                class="thumb"
            >
              <img
                  :alt="item.document.link"
                  :class="item.document.image ? 'wh40' : 'wh40 p6'"
                  :src="item.document.image ?
                    getImageFromProxy(item.document.image || '', '50:50') :
                     '/favicon.png'
                  "
                  loading="lazy"
              />
            </span>
            <span class="fg f fcol ft category_wrapper">
              <span class="link-breadcrumbs f fs gap4 fc">
                <template
                    v-for="(cat, i) of item.breadcrumbs"
                    :key="`link-breadcrumbs-${i}`"
                >
                  <nuxt-link
                      :class="{ell: item.breadcrumbs.length - 1 === i}"
                      :to="localePath(cat?.link || '/')"
                      class="category"
                      @click.stop
                  >

                    {{ cat?.title }}
                  </nuxt-link>
                  <span
                      v-if="item.breadcrumbs.length - 1 !== i"
                      class="f16 light fw400 pb2"
                  > > </span>
                </template>
              </span>
              <span class="name" v-html="item.document.title">
            </span>
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>


<script lang="ts" setup>
import {ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import {useResize} from '~/composables/resize.js'
import EventBus from '~/composables/bus'
import {useI18n} from 'vue-i18n'
import {simpleSearch} from '~/utils/api.js'
import type {SimpleSearch} from "~/graphql/generated/schema-types";

const uniqueId = useId();
const currW = useResize()

const {t, locale} = useI18n()
const {gtag} = useGtag()

const props = defineProps<{
  header: boolean
}>()

const route = useRoute()
const router = useRouter()
const debounce = useDebounce()
const localePath = useLocalePath()

const search_elem = ref<HTMLInputElement>()
const show = ref(false)
const items = ref<SimpleSearch[]>([])
const search = ref<string | null>(null)
const loading = ref(false)
const showClear = ref(false)
const selectedIndex = ref(-1)
const refs = ref({})

const onFocus = () => {
  show.value = !!items.value.length
  selectedIndex.value = 0
}

const setItem = (event: Event, name: string, link?: string) => {
  if (!props.header) {
    event.preventDefault()
    name = name.replace(/<\/?[^>]+>/g, '');
    search.value = name
    EventBus.$emit('comp_search', name)
    // EventBus.$emit('comp_search', search.value)

    nextTick().then(() => {
      show.value = false
    })

    if (search_elem.value) {
      search_elem.value.blur()
    }
  } else {
    search.value = null
    items.value = []
    showClear.value = false
    if (link) {
      router.push(getLink(link))
    }
  }
  selectedIndex.value = 0
  show.value = false
}


const fetchItems = async () => {
  if (search.value) {
    loading.value = true

    try {
      let {errors, data} = await simpleSearch(search.value, locale.value)

      if (errors?.length) {
        throw new Error(errors[0].message)
      }
      items.value = data.simpleSearch ? [...data.simpleSearch] : []
    } catch (e) {
      console.log('simpleSearch() error', (e as Error).message)
      EventBus.$emit('notify', {
        success: false,
        msg: (e as Error).message || 'An error occurred'
      })
    } finally {
      loading.value = false;
    }
  }
}

const debouncedFetchItems = debounce(fetchItems, 500);

watch(search, async (val) => {
  if (!val) {
    showClear.value = false
    show.value = false
    selectedIndex.value = 0
  }
  if (val && val.length) {
    debouncedFetchItems()
    showClear.value = true

    if (items.value) {
      show.value = true
    }
  }
})


const setSearch = () => {
  if (!props.header) {
    EventBus.$emit('comp_search', search.value)
    show.value = false

    if (props.header && search_elem.value) {
      search_elem.value.blur()
    }
  }
}

const clearSearch = () => {
  search.value = null
  show.value = false
  items.value = []
  showClear.value = false
  selectedIndex.value = 0

  if (!props.header) {
    EventBus.$emit('comp_search', search.value)
  }
}

const openAdvancedSearchModal = () => {
  EventBus.$emit('change_modal', {name: 'adv_search', value: true})
}

onMounted(() => {
  if (route.query && route.query.search) {
    setItem({preventDefault: () => undefined} as Event, route.query.search.toString())

    nextTick(() => {
      show.value = false
    })
  }
})


const onArrowUp = () => {
  if (selectedIndex.value > 0) {
    selectedIndex.value--
  } else {
    selectedIndex.value = items.value.length - 1
  }
}

const onArrowDown = () => {
  if (selectedIndex.value < items.value.length - 1) {
    selectedIndex.value++
  } else {
    selectedIndex.value = 0
  }
}

const onEnter = () => {
  if (selectedIndex.value >= 0 && selectedIndex.value < items.value.length) {
    if (!props.header) {
      setItem(
          {preventDefault: () => undefined} as Event,
          items.value[selectedIndex.value].document.title,
          items.value[selectedIndex.value].document.link
      )
      return
    }

    router.push(getLink(items.value[selectedIndex.value].document.link || ''))
    return;
  }

  if (!props.header) {
    setItem({preventDefault: () => undefined} as Event, search.value || '')
  }
};


const sendGtag = debounce(() => {
  if (!search.value) return

  gtag('event', 'csmarket-search', {
    search_term: search.value,
    event_label: 'Main search component(header, comparison...)'
  })
}, 1000)

const exteriors = ['factory-new', 'minimal-wear', 'field-tested', 'well-worn', 'battle-scarred'];

const getLink = (link: string) => {
  const exterior = exteriors.find(exterior => link?.includes(exterior));
  if (exterior) {
    link = link?.replace(new RegExp(`-(?=${exterior})`), '/');
  }

  return localePath(link);
}
</script>
<style lang="scss" scoped>
@use '/assets/scss/variables.scss' as *;
@use '/assets/scss/mixins.scss' as *;
@use 'sass:color';

.search {
  position: relative;


  input {
    padding-right: 3.8rem;
    width: 23rem;
  }

  &.search_header input {
    width: 100%;
    min-width: 25rem;
    max-width: 28rem;
    padding-left: 3.8rem;
    padding-right: 7.6rem;
  }

  &_wrap {
    position: absolute;
    top: 110%;
    z-index: 10;
    width: 100%;
  }

  .btn_advanced {
    padding: 0;
    color: $light;

    &:hover {
      color: $white;
    }

    svg {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
    }
  }

  &_list {
    background: #1B1F28;
    border-radius: 0.6rem;
    max-height: 50rem;
    overflow-y: auto;
    width: 100%;
    min-width: 25rem;
    max-width: 28rem;
    padding: 8px 0;
    overflow-x: hidden;

    &.p0 {
      padding: 0;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 12px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      padding: 4px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #333B4D;
      border-radius: 4px;
      border: 4px solid transparent; // Создает эффект узкого `thumb`
      background-clip: padding-box; // Центрирует `thumb` внутри скроллбара
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background-color: color.adjust(#333B4D, $lightness: 5%);
    }


    li {
      //  &:first-child {
      //    &:before {
      //      content: '';
      //      display: block;
      //      padding-bottom: 0.5rem;
      //    }
      //  }
      //
      //  &:last-child {
      //    &:after {
      //      content: '';
      //      display: block;
      //      padding-bottom: 0.5rem;
      //    }
      //  }

      &.is-selected {
        background: color.adjust(#1B1F28, $lightness: 5%);
      }
    }

    .category_wrapper {
      width: calc(100% - 95px);
    }

    .btn-primary {
      background: transparent;
      min-height: 5rem;
      height: auto;
      font-size: 1.2rem;
      border-radius: 0;
      padding: 8px 16px;
      color: $light;
      width: 100%;
      gap: 12px;

      &:hover,
      &:focus {
        background: color.adjust(#1B1F28, $lightness: 5%);
        color: $white;
      }

      .name {
        @include ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        width: 100%;
        overflow: hidden;

        &:hover {
          color: white;
        }

        &:deep(b) {
          color: $white;
        }
      }

      span {
        margin-left: 0;
      }

      .category {
        color: $light;
        font-size: 12px;
        font-weight: 500;

        &:hover {
          color: white;
          text-decoration: underline;
        }
      }

      .link-breadcrumbs {
        margin-bottom: 8px;
        width: 100%;
        overflow: hidden;
      }
    }

    .thumb {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        background: radial-gradient(
                circle,
                currentColor 0%,
                rgba(0, 0, 0, 0) 60%
        );
        opacity: 0.15;
      }

      img {
        position: relative;
      }
    }
  }
}

.btn-default {
  position: absolute;
  top: 0;
  right: 0;
}

.btn_clear {
  position: absolute;
  top: 0;
  right: 0;
  color: $light;
  width: 36px;
  min-width: unset;

  &.header {
    right: 40px;
  }

  &:hover {
    color: $white;
  }
}


@media screen and (max-width: 979px) {
  .search_header {
    .form_field {
      input {
        height: 4.5rem;
      }

      .icon,
      .btn {
        height: 4.5rem;
      }
    }

    .search_wrap {
      left: 0;
    }
  }
  .profile-nav_bottom__wrap {
    .search_header {
      .form_field {
        input {
          height: 5.6rem;
        }

        .icon,
        .btn {
          height: 5.6rem;
        }
      }

      .search_wrap {
        left: 0;
      }
    }
  }
}

@media screen and (max-width: 829px) {
  .search.search_header {
    .form_input {
      width: 100%;
      max-width: unset;
    }
  }
}

@media screen and (max-width: 768px) {
  .search {
    flex-grow: 1;

    input {
      width: 100%;
      max-width: initial;
    }

    &_wrap {
      left: -5rem;
      right: 0;
    }

    &_list {
      max-height: 20rem;
    }
  }
}
</style>
